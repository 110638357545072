.app-left-navigation.ant-menu {
  min-height: unset;
}

.app-left-navigation .ant-menu-sub.ant-menu-inline {
  background: #f1f2f4;
}

.app-left-navigation .ant-menu-item a,
.app-left-navigation .ant-menu-submenu-title,
.ant-select-selection-item a,
.ant-select-item-option-content a {
  color: #333333;
  display: block;
}

.app-left-navigation.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.app-left-navigation .ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: transparent;
  font-weight: 500;
}

.app-left-navigation.ant-menu-vertical .ant-menu-item::after,
.app-left-navigation.ant-menu-vertical-left .ant-menu-item::after,
.app-left-navigation.ant-menu-vertical-right .ant-menu-item::after,
.app-left-navigation.ant-menu-inline .ant-menu-item::after {
  left: 0;
  /* border: 4px solid #ff825c; */
  border-right: 0px;
  background: #ff825c3a;
}

.app-left-navigation
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.app-left-navigation
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow,
.app-left-navigation .ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #333333;
}

.desktop-navigation {
  display: block;
}

.mobile-navigation {
  display: none;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .desktop-navigation {
    display: none;
  }
  .mobile-navigation {
    display: block;
  }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .desktop-navigation {
    display: block;
  }
  .mobile-navigation {
    display: none;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .desktop-navigation {
    display: none;
  }
  .mobile-navigation {
    display: block;
  }
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  .desktop-navigation {
    display: none;
  }
  .mobile-navigation {
    display: block;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .desktop-navigation {
    display: none;
  }
  .mobile-navigation {
    display: block;
  }
}
