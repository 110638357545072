* {
  margin: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.login-right-input {
  margin: 5px;
}
.login-right-input-prefix {
  border-radius: 20px;
}
.login-right-button {
  border: none;
  background-color: #2e186a;
  font-size: 15px;
  font-weight: bolder;
  color: white;
  border-radius: 30px;
  outline: none;
  transition: 0.5s ease-in-out;
}
.login-right-button:hover {
  box-shadow: 0px 0px 5px 1px black;
  transition: 0.5s ease-in-out;
}
.login-right-button::after {
  transition: 2s ease-in-out;
}

.login-right-button:active:after {
  transition: 0s;
}
.loginright > p {
  font-weight: bold;
}
.loginright img {
  margin: 0px 10px;
}
.login-form-forgot {
  cursor: pointer;
  color: #2e186a;
}
@media (max-width: 800px) {
  .login-right-input {
    background: white;
    width: 250px;
  }
  .login-right-button {
    background: white;
    color: #2e186a;
  }
}
