.submit-art-work {
  position: relative;
  min-height: 2.75rem;
}
.submit-art-btn {
  position: absolute;
  top: 0px;
  left: 15px;
}
.submit-art-btn .sml {
  display: none;
}
.submit-art-btn Button {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: black;
}
.submit-art-btn Button:hover {
  border: none;
  color: black;
  border: 1px solid #e6e6e6;
  background-color: #e6e6e6;
}
.art-work-list {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.upload-art-work-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  cursor: pointer;
  font-size: 2.75rem;
}
.upload-art-work-button:hover {
  color: #e23825;
}
@media (max-width: 900px) {
  .submit-art-btn .lrg {
    display: none;
  }
  .submit-art-btn .sml {
    display: initial;
  }
}
