.upload-art .ant-modal-body {
  background-color: #f1f2f4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ant-card-head-title {
  overflow: unset !important;
  white-space: break-spaces !important;
}

.notification__item--success {
  background-color: #4fc0d9;
}
.close-btn-btn {
  top: 130px;
  right: 30px;
}
.loading {
  height: 86px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-device-width: 667px) {
  .close-btn-btn {
    top: 170px;
  }
}
